import { Box, Grid, Typography, Link } from '@mui/material'
import * as React from 'react'
import { Privacy } from './components/Privacy'

export function HomePage() {
  const ref = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState(false)
  const handleOpenPrivacy = () => {
    setOpen(true)
  }
  const handleClosePrivacy = () => {
    setOpen(false)
  }

  return (
    <Box
      sx={{ pb: 7, width: '100%', backgroundColor: 'primary.main' }}
      ref={ref}
    >
      <Grid
        container
        sx={{
          backgroundColor: 'primary.main',
          top: '4em',
          width: '100%',
          position: 'absolute',
          bottom: '1em',
          padding: '2em',
          left: 0
        }}
      >
        <Grid item xs={6}>
          <Typography
            variant="h4"
            sx={{ borderBottom: 1, marginBottom: '0.5em' }}
          >
            What is it and How It Works?
          </Typography>
          <Typography variant="body1">
            The app helps you memorize associations effectively by using spaced
            repetition and adaptive algorithms. It tracks your progress and
            performance, and adjusts the frequency and difficulty of the{' '}
            <b>flash cards</b> accordingly. It also gives you feedback and tips
            on how to improve your memory. What are flash cards?
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>
                Flash cards are a popular and effective way to memorize
                associations like words on different languages.
              </li>
              <li>
                Flash cards are small cards with a word or image on one side and
                the associated word or image on the other side.
              </li>
              <li>
                Flash cards help learners to associate the words with their
                meanings, imagination, and usage.
              </li>
              <li>
                Flash cards can be used in various ways, such as reviewing,
                testing, sorting, or playing games.
              </li>
              <li>
                Flash cards can be made by hand, printed, or accessed online or
                through apps.
              </li>
            </ul>
          </Typography>
          <Typography variant="body1">
            All flash cards are joined into one deck. Any decks is assigned to
            the specific category. Pick your one from list to start and see how
            you can improve your memory and fill the lack in your knowledge.
          </Typography>

          <Grid
            sx={{ marginTop: '3em' }}
            container
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Link href="https://play.google.com/store/apps/details?id=memo.cards.framework">
                <img
                  height={110}
                  src="./img/google-play-badge.png"
                  alt="Google Play"
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Link
                sx={{
                  marginRight: 'auto'
                }}
                href="https://youtu.be/2-_Nly5FxHQ?si=Y91m-D9Y5tjFXZdP"
              >
                <img height={110} src="./img/youtube.png" alt="Google Play" />
              </Link>
            </Grid>
          </Grid>
          <Typography variant="body2" sx={{ color: 'white', borderTop: 1 }}>
            <ul
              style={{
                fontSize: '11px',
                display: 'flex',
                float: 'right',
                listStylePosition: 'outside',
                listStyleType: 'none'
              }}
            >
              <li style={{ paddingLeft: '19px', cursor: 'pointer' }}>
                <a
                  style={{ color: 'white', textDecoration: 'none' }}
                  onClick={handleOpenPrivacy}
                >
                  Privacy and Cookies
                </a>
              </li>
              <li style={{ paddingLeft: '19px' }}>
                <a
                  style={{ color: 'white', textDecoration: 'none' }}
                  href="mailto:support@boomza.com"
                >
                  Contact us
                </a>
              </li>
              <li style={{ paddingLeft: '19px' }}>© Boomza 2025</li>
            </ul>
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center'
          }}
        >
          <Box
            component="div"
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              alignContent: 'center',
              width: 400,
              height: 780,
              paddingLeft: 0,
              paddingTop: '40px',
              backgroundImage: `url(${'./img/android.png'})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <iframe
              style={{
                top: 10,
                left: 0,
                border: 0
              }}
              height={700}
              width={356}
              src="https://boomza.com/mobile"
            />
          </Box>
        </Grid>
      </Grid>
      <Privacy isOpen={open} onClick={handleClosePrivacy} />
    </Box>
  )
}
